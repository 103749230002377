.progress {
  display: flex;
  justify-content: center;
}

.chart_box {
  width: 28rem;
  position: absolute;
  z-index: 1;
  top: 6rem;
}

.chart_wrapper {
  max-width: 36.5rem;
  flex: 1 1 100%;
  height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 991px) {
    margin-bottom: 5.5rem;
  }
  @media (max-width: 768px) {
    height: 31.5rem;
    max-width: 31.5rem;
  }
  @media (max-width: 370px) {
    height: 25.5rem;
    max-width: 25.5rem;
    margin-bottom: 3.5rem;
  }
  &::after {
    content: "";
    box-shadow: 0px 0.3rem 1rem #00000029;
    position: absolute;
    height: 30rem;
    width: 30rem;
    border-radius: 100%;
    opacity: 0.5;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// .profPic {
//     width: 23rem;
//     height: 23rem;
//     position: relative;
//     top: -25.5rem;
//     left: 2.3rem;
//     z-index: -1;
//     border-radius: 100%;
// }

@media only screen and (max-width: 600px) {
  .progress {
    display: flex;
    justify-content: center;
    padding-top: 5rem;
  }

  .chart_box {
    width: 16rem;
    position: absolute;
    z-index: 1;
    top: 8rem;
  }
}

.chartMain {
  position: relative;
  @media (max-width: 600px) {
    // position: initial;
  }
  .chart {
    height: 300px;
    // .chartInner {
    //     height: 300px;
    // }
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
    + .profPic {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 200px;
      height: 200px;
      border-radius: 50%;

      @media (max-width: 600px) {
        width: 182px;
        height: 182px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      &::after {
        content: "";
        box-shadow: 0px 0.3rem 1rem #00000029;
        position: absolute;
        height: 27rem;
        width: 27rem;
        border-radius: 100%;
        opacity: 0.5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (min-width: 1600px) {
          width: 22rem;
          height: 22rem;
        }
        @media (max-width: 600px) {
          height: 26rem;
          width: 26rem;
        }
      }
    }
  }
}

html[dir="rtl"] {
  .chartMain {
    .chart {
      svg {
        text {
          direction: ltr;
        }
      }
    }
  }
}
