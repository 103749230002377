.loader {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
}
.loader:before {
  animation: before 2s infinite;
}
.loader:after {
  animation: after 2s infinite;
}

@keyframes before {
  0% {
    width: 0.5em;
    box-shadow: 1em -0.5em var(--color-PrimaryColor1),
      -1em 0.5em var(--color-PrimaryColor1);
  }
  35% {
    width: 2.5em;
    box-shadow: 0 -0.5em var(--color-PrimaryColor1),
      0 0.5em var(--color-PrimaryColor1);
  }
  70% {
    width: 0.5em;
    box-shadow: -1em -0.5em var(--color-PrimaryColor1),
      1em 0.5em var(--color-PrimaryColor1);
  }
  100% {
    box-shadow: 1em -0.5em var(--color-PrimaryColor1),
      -1em 0.5em var(--color-PrimaryColor1);
  }
}
@keyframes after {
  0% {
    height: 0.5em;
    box-shadow: 0.5em 1em var(--color-PrimaryColor1),
      -0.5em -1em var(--color-PrimaryColor1);
  }
  35% {
    height: 2.5em;
    box-shadow: 0.5em 0 var(--color-PrimaryColor1),
      -0.5em 0 var(--color-PrimaryColor1);
  }
  70% {
    height: 0.5em;
    box-shadow: 0.5em -1em var(--color-PrimaryColor1),
      -0.5em 1em var(--color-PrimaryColor1);
  }
  100% {
    box-shadow: 0.5em 1em var(--color-PrimaryColor1),
      -0.5em -1em var(--color-PrimaryColor1);
  }
}

.loader {
  position: absolute;
  top: calc(50% - 1.25em);
  left: calc(50% - 1.25em);
}
.loader_container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // width: 100%;
  // height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1999;
}
.loader__gif {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader__message {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: #707070;
  font-size: 1.8rem;
}
