.homeNavbarLink {
  font-size: 1.6rem;
  height: inherit;
  display: flex;
  margin: 0rem 2rem;
  align-items: center;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
  text-decoration: none;
  // min-width: 4rem;
  justify-content: space-between;
  &__active {
    color: var(--color-PrimaryColor1);
    border-bottom: 0.3rem solid var(--color-PrimaryColor1);
  }
  img:nth-of-type(1) {
    margin: 1rem;
  }
  @media (min-width: 1600px) {
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .homeNavbarLink {
    margin: 0rem 1rem;
    white-space: nowrap;
  }
}


html[dir="rtl"] {
  .homeNavbarLink {
    img:nth-of-type(1) {
      transform: rotate(180deg);
    }
    + svg{
      transform: rotate(180deg);
      
    }
  }
}