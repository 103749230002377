@import "abstracts/variables";

@import "base/base";
@import "base/utilities";

@import "components/checkbox";
@import "components/radio";
@import "components/scrollbar";

@font-face {
  font-family: "Aller", sans-serif;
  src: local("Aller"), url("../fonts/Aller_Std_Bd.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Aller", sans-serif;
  src: local("Aller"), url("../fonts/Aller_Std_BdIt.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Aller", sans-serif;
  src: local("Aller"), url("../fonts/Aller_Std_It.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Aller Light", sans-serif;
  src: local("Aller"), url("../fonts/Aller_Std_Lt.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Aller Light", sans-serif;
  src: local("Aller"), url("../fonts/Aller_Std_LtIt.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Aller", sans-serif;
  src: local("Aller"), url("../fonts/Aller_Std_Rg.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Aller Display", sans-serif;
  src: local("Aller"),
    url("../fonts/AllerDisplay_Std_Rg.ttf") format("truetype");
  font-weight: 400;
}
