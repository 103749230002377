.extendTimer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  &__subHeader {
    font-size: 2.1rem;
    color: #404040;
    max-width: max-content;
    white-space: nowrap;
  }
  &__buttonContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    &__logoutLink {
      font-size: 1.8rem;
      color: var(--color-PrimaryColor1);
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
    &__primaryThemeButton {
      border: 0.1rem solid var(--color-PrimaryColor1);
      background: var(--color-PrimaryColor1) 0% 0% no-repeat padding-box;
      border-radius: 0.7rem;
      width: 14.5rem;
      height: 5rem;
      font-size: 1.8rem;
      font-weight: bold;
      color: #f4f4f4;
      margin-bottom: 1rem;
    }
  }
}
