.homeSidebarLink {
  width: 100%;
  height: 4.3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #404040;
  font-size: 1.5rem;
  &__active {
    color: #fff;
    font-weight: bold;
    background-color: var(--color-PrimaryColor1);
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
  }
  .name {
    width: 65%;
    padding-left: 1rem;
    @media (max-width: 600px) {
      width: initial;
    }
  }
  .iconArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
  }
}
