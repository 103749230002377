.hideFooterContainer {
  display: none;
}
.heightAboveFooter{
  height: 50px;
}
.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 3rem 0rem;
  position: absolute;
  bottom: 5px;
  width: 100%;
  // height: 4.5rem;
  .footerList {
    list-style: none;
    // font-size: 1.4rem;
    // color: #adadad;
    color: #333333;
    font-size: 2.2rem;
    @media (min-width: 1600px) {
      font-size: 22px;
    }
    @media (max-width: 600px) {
      font-size: 12px;
    }
    & > li {
      cursor: pointer;
      display: inline-block;
      margin: 0rem 1.5rem;
      color: #4242fb;
      border-bottom: 0.2rem solid #4242fb;
      @media (min-width: 1600px) {
        margin: 0 15px;
      }
      @media (max-width: 600px) {
        margin: 0 10px;
      }
    }
    // & > li:not(:first-child){
    //   color: #4242fb;
    //   border-bottom: 0.3rem solid #4242fb;
    // }
    & > li:first-child {
      color: #333333;
      border-bottom: none;
    }

    &__active {
      color: var(--color-PrimaryColor1) !important;
      font-weight: bold;
      border-bottom: 0.3rem solid var(--color-PrimaryColor1) !important;
    }
    .first {
      cursor: default;
    }
  }
}
@media only screen and (max-width: 480px) {
  .footerList {
    font-size: 1.2rem;
    & > li {
      margin: 1.2rem;
    }
  }
}
