.homeNavbar {
  height: 5.6rem;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  box-shadow: inset 0rem 0.3rem 1rem #0000001a, 0rem 0.6rem 1rem #00000024;
  padding: 0rem 2.8rem;

  &__filter {
    display: none;
    justify-content: center;
    position: absolute;
    height: 3.5rem;
    width: 3.5rem;
    top: 8rem;
    right: 2rem;
    border-radius: 100%;
    align-items: center;
    background: #fff;
    border: 0.1rem solid #707070;
    img {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
  &__homeLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    flex-wrap: wrap;

    &.globalSearchLinks {
      @media (max-width: 600px) {
        &.homenavbar__mobile {
          display: none;
        }
        .homeNavbar__filterAndFavourite {
          &:nth-child(2) {
            // display: none;
          }
        }
        overflow-x: auto;
        padding: 0 0px;
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        flex-wrap: initial;
      }
      .homeNavbar__links {
        &.homenavbar__mobile {
          @media (max-width: 600px) {
            // display: none;
            justify-content: center;
            // margin-right: 0;
          }
        }
        .checkbox_section {
          & > label {
            margin-right: 0.8rem;
            @media (min-width: 1600px) {
              font-size: 18px;
            }
          }
          .moreDropdownContainer {
            position: relative;
            @media (max-width: 600px) {
              position: initial;
            }
            button {
              svg {
                width: 2.5rem;
                height: 2.5rem;
              }
            }
            .threeDots {
              list-style-type: none;
              padding: 0 15px;
              cursor: pointer;
              li {
                width: 4px;
                height: 4px;
                display: block;
                background-color: #707070;
                border-radius: 50%;
                margin: 2px 0;
              }
            }
            .threeDotsDropDownContainer {
              border-radius: 4px;
              box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
                0px 8px 10px 1px rgb(0 0 0 / 14%),
                0px 3px 14px 2px rgb(0 0 0 / 12%);
              position: absolute;
              overflow-y: auto;
              overflow-x: hidden;
              min-width: 16px;
              min-height: 16px;
              max-width: 300px;
              max-height: 300px;
              outline: 0;
              background-color: #fff;
              top: 30px;
              @media (max-width: 600px) {
                top: 57px;
                right: 20px;
              }
              ul {
                list-style-type: none;
                padding: 8px 0px;
                li {
                  padding: 6px 16px;
                }
              }
            }
          }
        }
        .result_count {
          padding: 0 1rem;
          font-size: 1.5rem;
          line-height: 1.7rem;
          color: var(--color-PrimaryColor1);
          @media (min-width: 1600px) {
            font-size: 18px;
          }
          @media (max-width: 600px) {
            font-size: 14px;
            // width: 105px;
            white-space: nowrap;
            em {
              display: block;
              white-space: nowrap;
            }
          }
        }
        @media (max-width: 768px) {
          // .homeNavbar__filterAndFavourite {

          //   display: none;

          // }
        }
        .homeNavbar__filterAndFavourite {
          position: relative;
          margin: 0 1.5rem;
          // @media (max-width: 600px) {
          //   display: none;
          // }
          &:first-child {
            display: none;
          }
          @media (max-width: 600px) {
            //  show favourite in RWD
            &:nth-child(2) {
              margin: 0;
            }

            &:last-child {
              // display: none; //hide this to show favourite in RWD
              margin: 0 0.9rem;
            }
          }
          .homeNavbar__filterAndFavourite__link {
            margin: 0 0;
          }
          a {
            margin: 0 0;
          }
          .contentLangDropdown {
            // width: 23.1rem;
            width: 24.1rem;
            background-color: #ffffff;
            box-shadow: 0rem 0.5rem 1rem #00000017;
            border: 0.1rem solid #e8e8e8;
            position: absolute;
            top: 100%;
            right: 0;
            padding: 1.5rem 1.5rem;
            .dropdown_heading {
              letter-spacing: 0px;
              color: #333333;
              font-size: 1.6rem;
              line-height: 2rem;
              font-weight: bold;
              margin-bottom: 0.9rem;
            }
            .language_searchbox {
              position: relative;
              display: flex;
              align-items: center;
              text-align: center;
              margin-bottom: 1.1rem;
              img {
                position: absolute;
                left: 1rem;
              }
              input {
                width: 100%;
                border: 0.1rem solid #cbcbcb;
                border-radius: 3rem;
                height: 3rem;
                padding-left: 3rem;
                padding-right: 1.5rem;
                font-size: 1.4rem;
                outline: none;
                color: #707070;
                &::placeholder {
                  color: #70707075;
                }
              }
            }
            .language_list {
              max-height: 17rem;
              overflow-y: auto;
              &::-webkit-scrollbar {
                width: 1rem;
              }
              &::-webkit-scrollbar-track {
                background: #fff;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 1rem;
                background: #ececec;
              }
              .language_list_item {
                margin-bottom: 0.9rem;
                input {
                }
                p {
                }
              }
            }
          }
          .filterDropdown {
            // width: 23.1rem;
            width: 24.1rem;
            background-color: #ffffff;
            box-shadow: 0rem 0.5rem 1rem #00000017;
            border: 0.1rem solid #e8e8e8;
            position: absolute;
            top: 100%;
            right: 0;
            padding: 1.5rem 1.5rem;
            @media (max-width: 600px) {
              right: initial;
              left: 10px;
              animation: drop 0.5s ease forwards;
              top: 5.7rem;
              @keyframes drop {
                0% {
                }
                70% {
                  transform: translateY(10px);
                }
                100% {
                  transform: translateY(0);
                }
              }
            }
            .dropdown_header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .heading_text {
                letter-spacing: 0px;
                color: #333333;
                font-size: 1.6rem;
                line-height: 2rem;
              }
              .action_box {
                .btn_reset {
                  letter-spacing: 0px;
                  color: #333333;
                  font-size: 1.4rem;
                  line-height: 1.7rem;
                  cursor: pointer;
                  border: none;
                  background-color: transparent;
                  img {
                    margin-right: 0.6rem;
                    width: 1.8rem;
                    vertical-align: text-bottom;
                  }
                }
              }
            }
            .filterBody {
              margin-top: 0.9rem;

              .filterBody_heading {
                letter-spacing: 0px;
                color: #333333;
                font-size: 1.2rem;
                line-height: 1.5rem;
                font-weight: bold;
                margin-bottom: 1.1rem;
              }
              .filterBody_list {
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                .filterBody_list_item {
                  flex: 1 1 100%;
                  max-width: 50%;
                  margin-bottom: 0.9rem;
                  label {
                    font-size: 1.4rem;
                    padding-left: 2.6rem;
                    padding-right: 0.3rem;
                    @media (min-width: 1600px) {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
            .trainingTypes {
              .trainingType_header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 0.9rem;
                margin-bottom: 0.5rem;
                .heading_text {
                  letter-spacing: 0px;
                  color: #333333;
                  font-size: 1.2rem;
                  // font-size: 1rem;
                  line-height: 1.5rem;
                }
                .trainingType_searchbox {
                  position: relative;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  max-width: 11.8rem;
                  flex: 1 1 100%;
                  img {
                    position: absolute;
                    left: 1rem;
                    width: 1.6rem;
                  }
                  input {
                    width: 100%;
                    border: 0.1rem solid #cbcbcb;
                    border-radius: 3rem;
                    height: 3rem;
                    padding-left: 3rem;
                    padding-right: 1.5rem;
                    font-size: 1.2rem;
                    outline: none;
                    color: #707070;
                    &::placeholder {
                      color: #70707075;
                    }
                  }
                }
              }
              .trainingType_list {
                max-height: 17rem;
                overflow-y: auto;
                &::-webkit-scrollbar {
                  width: 1rem;
                }
                &::-webkit-scrollbar-track {
                  background: #fff;
                }
                &::-webkit-scrollbar-thumb {
                  border-radius: 1rem;
                  background: #ececec;
                }
                .trainingType_list_item {
                  margin-bottom: 0.9rem;
                  label {
                    font-size: 1.4rem;
                    padding-left: 2.6rem;
                    padding-right: 0.3rem;
                    @media (min-width: 1600px) {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 1600px) {
    height: 57px;
  }
}
// .homeNavbar__homeNavbarfilterAndFavouritemobile {
//     display: none;
// }

.homeNavbar__links {
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    &.homenavbar__mobile {
      // display: none; //remove space for favourite in RWD
    }
    overflow-x: auto;
    margin-right: 1rem;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    svg {
      width: 19px;
      overflow: initial;
    }
  }

  .homeNavbar__filterAndFavourite {
    display: flex;
    height: inherit;
    align-items: center;
    margin: 0rem 1rem;
    position: relative;
    @media (max-width: 768px) {
      position: inherit;
    }
    &__link {
      display: flex;
      margin: 0rem 2rem;
      cursor: pointer;
    }
  }
  &__creditStateType {
    display: flex;
    @media (min-width: 1600px) {
      align-items: center;
    }
    @media (max-width: 600px) {
      // display: none;
      + .homeNavbar__filterAndFavourite {
        display: none;
      }
    }

    &__stateType {
      display: flex;
      font-size: 1.6rem;
      color: #707070;
      @media (min-width: 1600px) {
        font-size: 18px;
      }
    }
    &__stateTypeActive {
      color: var(--color-PrimaryColor1);
      font-weight: bold;
      @media (min-width: 1600px) {
        font-size: 18px;
      }
    }
    &__toggleBtn {
      margin: 0rem 0.8rem;
      width: 5.9rem;
      height: 2.5rem;
      background: #ffffff;
      border-radius: 3rem;
      padding: 0.2rem;
      border: 0.1rem solid var(--color-PrimaryColor1);
      transition: all 300ms ease-in-out;
      position: relative;
      cursor: pointer;
      @media (min-width: 1600px) {
        width: 4.9rem;
        height: 2rem;
      }

      &__innerCircle {
        position: absolute;
        width: 1.9rem;
        height: 1.9rem;
        background: var(--color-PrimaryColor1);
        border-radius: 50%;
        transition: all 300ms ease-in-out;
        transform: translateX(0.1rem);
        opacity: 1;
        @media (min-width: 1600px) {
          width: 1.3rem;
          height: 1.3rem;
        }
      }
      &__innerCircleRight {
        width: 1.9rem;
        height: 1.9rem;
        background: var(--color-PrimaryColor1);
        border-radius: 50%;
        transition: all 300ms ease-in-out;
        cursor: pointer;
        transform: translateX(3.3rem);
        opacity: 1;
        position: absolute;
        @media (min-width: 1600px) {
          width: 1.3rem;
          height: 1.3rem;
          transform: translateX(3rem);
        }
      }
    }
  }
}

.tooltip {
  position: relative;
  display: flex;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 9.7rem;
  background-color: #404040;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.5rem;
  padding: 0.6rem 0.6rem;
  @media (max-width: 600px) {
    width: 70px;
    font-size: 10px;
  }
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #404040 transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-width: 768px) {
  .homeNavbar {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .tooltip:hover .tooltiptext {
    width: 70px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .homeNavbar {
    &__filter {
      display: flex;
      // display: none;
    }
    padding: 0rem;
    position: relative;
  }
}
.custom-checkbox {
  padding: 20px;
}

.backToReport {
  font-size: 1.6rem;
  height: inherit;
  display: flex;
  margin: 0rem 2rem;
  align-items: center;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
  text-decoration: none;
  // min-width: 4rem;
  justify-content: space-between;
  &__active {
    color: var(--color-PrimaryColor1);
    border-bottom: 0.3rem solid var(--color-PrimaryColor1);
  }
  img:nth-of-type(1) {
    margin: 1rem;
  }
  @media (min-width: 1600px) {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .backToReport {
    margin: 0rem 1.4rem;
  }
}

.homeNavbarfilterAndFavouritemobile {
  @media (max-width: 600px) {
    display: none;
  }
}

html[dir="rtl"] {
  .homeNavbar__links {
    &__creditStateType {
      direction: ltr;
    }
  }
  .homeNavbar {
    &__homeLinks {
      &.globalSearchLinks {
        .homeNavbar__links {
          .homeNavbar__filterAndFavourite {
            .filterDropdown {
              right: initial;
              left: 0;
              .dropdown_header {
                .action_box {
                  .btn_reset {
                    img {
                      margin-left: 0.6rem;
                      margin-right: 0;
                    }
                  }
                }
              }
              .filterBody {
                .filterBody_list {
                  .filterBody_list_item {
                    label {
                      text-align: left;
                    }
                  }
                }
              }
              .trainingTypes {
                .trainingType_list {
                  .trainingType_list_item {
                    label {
                      padding-right: 2.6rem;
                      padding-left: 0.3rem;
                    }
                    span {
                      left: initial;
                      right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .homeNavbarMobileFilter {
    height: 0;
  }
  .homeNavbar {
    &__homeLinks {
      &.globalSearchLinks {
        .homeNavbar__links {
          .homeNavbar__filterAndFavourite {
            position: inherit;
          }
        }
      }
    }
  }

  .opaqueLayer {
    &::after {
      content: "";
      position: fixed;
      background-color: rgba(0, 0, 0, 0.37);
      width: 100%;
      top: 53px;
      height: 100%;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }
}
html[data-language="de"] {
  .homeNavbar {
    &__homeLinks {
      &.globalSearchLinks {
        .homeNavbar__links {
          .homeNavbar__filterAndFavourite {
            .filterDropdown {
              width: 26.1rem;
            }
          }
        }
      }
    }
  }
}
