.noConversationFound {
  text-align: center;
  // margin: 8rem 0;
  // margin: 4rem 0;
  @media(max-width:768px){
    padding: 0.5rem 3rem;
  }
  img {
    // width: 28.8rem;
    width: 18rem;
    margin-bottom: 0.6rem;
    @media(max-width: 600px){
      width: 15rem;
    }
  }
  h2 {
    // font-size: 5.5rem;
    font-size: 4rem;
    line-height: 6.6rem;
    letter-spacing: 0px;
    color: #404040;
    @media (min-width: 1600px) {
      font-size: 35px;
    }
    @media(max-width: 600px){
      font-size: 2.5rem;
      line-height: 3.2rem;
    }
    @media(max-width: 370px){
      font-size: 2.8rem;
      line-height: 3.5rem;
    }
  }
}
