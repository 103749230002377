.homeSidebar__hide {
  height: 100vh;
  width: 50vw;
  position: absolute;
  background-color: #fff;
  top: 5.4rem;
  transform: translateX(-101%);
  transition: transform 0.4s;
  //   animation: slideOut 500ms;
  z-index: 99;
}
.homeSidebar {
  height: 100vh;
  // width: 50vw;
  width: 62vw;
  position: absolute;
  background-color: #fff;
  top: 5.4rem;
  z-index: 99;
  transform: translateX(0%);
  transition: transform 0.4s;
  //   animation: slideIn 500ms;
  + .sideNavbarOpaqueLayer {
    content: "";
    position: fixed;
    background-color: rgb(0 0 0 / 37%);
    width: 100%;
    top: 53px;
    height: 100%;
    left: 0;
    right: 0;
    z-index: 2;
  }
}
.homeSidebar__list {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  &__links {
    height: 100%;
    width: 100%;
  }
  &__logout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 9rem;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes slideOut {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (min-width: 600px) {
  .homeSidebar {
    display: none;
  }
  .homeSidebar__hide {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  html[dir="rtl"] {
    .homeSidebar__hide {
      transform: translateX(101%);
    }
  }
}
