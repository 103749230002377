  .filter {
  position: absolute;
  top: 100%;
  right: 1rem;
  width: 231px;
  // height: 152px;
  min-height: 152px;
  padding: 1rem 1.5rem 0.8rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0rem 0.5rem 1rem #00000017;
  border: 0.1rem solid #e8e8e8;
  cursor: auto;
  background-color: #fff;
  z-index: 99;
  @media (min-width: 1600px) {
    width: 350px;
    height: auto;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    @media (min-width: 1600px) {
      justify-content: initial;
      width: 100%;
    }
    &__heading {
      font-size: 1.6rem;
      font-weight: bold;
      color: #333333;
      @media (min-width: 1600px) {
        font-size: 20px;
        flex-grow: 1;
      }
    }
    &__img {
      cursor: pointer;
      margin-right: 0.2rem;
      @media (min-width: 1600px) {
        margin: 0;
        padding-right: 10px;
      }
    }
    &__reset {
      color: #707070;
      display: flex;
      align-items: center;
      @media (min-width: 1600px) {
        font-size: 18px;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    height: 70%;
    justify-content: space-around;
    @media (min-width: 1600px) {
      height: auto;
    }
    &__heading {
      font-size: 1.37rem;
      font-weight: bold;
      color: #333333;
      @media (min-width: 1600px) {
        font-size: 18px;
        margin-top: 10px;
      }
    }
    &__list {
      display: flex;
      list-style: none;
      font-size: 1.4rem;
      color: #333333;
      width: 100%;
      height: 65%;
      flex-wrap: wrap;
      li {
        width: 50%;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 10px 0;
        padding: 0 2px;
        @media (min-width: 1600px) {
          margin: 10px 0;
          font-size: 18px;
        }
        input {
          margin-right: 0.5rem;
          flex-shrink: 0;
        }
      }
    }
  }
}
// .checkmark {
//   border: 1px solid gray;
//   width: 1.7rem;
//   height: 1.7rem;
//   display: block;
//   border-radius: 50%;
//   position: absolute;
//   top: 0rem;
//   background-color: white;
// }
// input:checked ~ .checkmark {
//   border: 0.1rem solid #5e5e5e;
// }

// input:checked ~ .checkmark:after {
//   content: "";
//   background-color: #646464;
//   height: 0.8rem;
//   width: 0.8rem;
//   display: block;
//   border-radius: 50%;
//   position: absolute;
//   top: 0.33rem;
//   left: 0.33rem;
// }
.box {
  position: relative;
  margin: 0.3rem 0rem;
  display: flex;
  align-items: center;
  & > input {
    margin-right: 0.8rem;
  }
}
input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 1.9rem;
  height: 1.9rem;
  padding: 0.37rem;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #5e5e5e;
  border-radius: 50%;
  cursor: pointer;
}
input[type="radio"]:checked {
  background-color: #646464;
}
.option {
  height: 100%;
  color: #333333;
  font-size: 1.4rem;
  opacity: 1;
}

html[dir="rtl"] {
  .filter{
    right: initial;
    left: 1rem;
    &__header{
      &__img{
        padding-right: 0;
        padding-left: 10px;
      }
    }
    &__body {
      &__list {
        li {
          input {
            margin-left: 0.5rem;
            margin-right: 0;
          }
        }
      }
    }
  }
}