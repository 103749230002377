.noInternetPageHide {
  display: none;
}
.noInternetPage {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1052;
  background-color: #fff;
  // height: 100vh;
  // width: 100%;
  &__main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__errorMessage {
      font-size: 4.5rem;
      color: #0e0e0e;
      margin-right: 1.5rem;
      @media (max-width: 769px) {
        text-align: center;
        margin-right: 0;
        font-size: 3rem;
      }
    }
  }
}
