.quizTimer {
    display: flex;
    position: absolute;
    right: 5rem;
    align-items: center;
    @media (max-width: 600px) {
        flex-grow: 1;
        margin-right: 1rem;
        position: initial;
        justify-content: flex-end;
    }
    .quizTimerText {
        padding-left: 1rem;
        font-size: 15px;
        line-height: 18px;
        font-weight: bold;
        letter-spacing: 0px;
        .timerText {
            color: #404040;
        }
        .timer {
            padding-left: 1rem;
            color: var(--color-PrimaryColor1);
        }
    }
}


html[dir="rtl"] {
    .quizTimer {
        right: initial;
        left: 5rem;
        .quizTimerText{
            padding-left: 0;
            padding-right: 1rem;
        }
    }
}