.homeHeaderProfile {
  height: 24rem;
  width: 23rem;
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0rem 0.5rem 1rem #00000017;
  border: 0.1rem solid #e8e8e8;
  top: 7rem;
  right: 1rem;
  cursor: auto;
  text-align: center;
  align-items: center;
  color: #333333;
  padding: 0.8rem 0.8rem 0rem 0.8rem;
  z-index: 1;
  @media (min-width: 1600px) {
    top: 5.3rem;
  }
  @media (max-width: 600px) {
    top: 5.3rem;
    width: 100%;
    right: 0;
    left: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-delay: 0s;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    @keyframes slideInDown {
      0% {
        -webkit-transform: translate3d(0, -5%, 0);
        transform: translate3d(0, -5%, 0);
        visibility: visible;
      }
      to {
        -webkit-transform: translateZ(10px);
        transform: translateZ(10px);
      }
    }
    + .profileOpaqueLayer {
      content: "";
      position: fixed;
      background-color: rgb(0 0 0 / 37%);
      width: 100%;
      top: 53px;
      height: 100%;
      left: 0;
      right: 0;
    }
  }
}

.homeHeaderProfile__imageAndName {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 14rem;
  width: 100%;
}
.homeHeaderProfile__imageAndName::before {
  content: "";
  position: absolute;
  height: 12.5rem;
  width: 12.5rem;
  border-radius: 100%;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.08);
}
.homeHeaderProfile__imageAndName::after {
  content: "";
  position: absolute;
  height: 9.5rem;
  width: 9.5rem;
  border-radius: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.homeHeaderProfile__image {
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 100%;
}
.homeHeaderProfile__fullName {
  position: absolute;
  font-weight: bold;
  bottom: 0.1rem;
  word-break: break-all;
}

.homeHeaderProfile__links {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
.homeHeaderProfile__link {
  display: flex;
  font-size: 1.4rem;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  color: #333333;

  cursor: pointer;
  text-decoration: none;
}

.homeHeaderProfile__link:hover {
  color: var(--color-PrimaryColor1);
}
.homeHeaderProfile__link__notification {
  position: relative;
  display: flex;
}

.homeHeaderProfile__link__notificationCount {
  width: 1.9rem;
  height: 1.9rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #eb0404 0% 0% no-repeat padding-box;
  position: absolute;
  border-radius: 1.8rem;
  top: -0.5rem;
  left: 0.8rem;
}
.homeHeaderProfile__link__lastVisited {
  text-align: center;
  color: #707070;
  font-size: 1.1rem;
}
html[dir="rtl"] .homeHeaderProfile {
  right: initial;
  left: 2rem;
}


html[data-language="fr"], html[data-language="de"], html[data-language="es"] {
  .homeHeaderProfile__link{
    font-size: 1.2rem;
  }
}