.LeaderboardModal {
  display: flex;
  justify-content: center;
  width: 100%;
}
.champion {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0rem 1rem;
}
.champion {
  min-width: 24rem;
  height: 26.7rem;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 1rem;
  opacity: 1;
}
.champion1 {
  background: #fbfbfb 0% 0% no-repeat padding-box;
}
.champion > img {
  margin-top: 3rem;
  padding: 5% 5% 5% 5%;
  border-radius: 50%;
  border: 0.2rem solid #f7bc14;
  @media (min-width: 1600px) {
    padding: 2% 2% 2% 2%;
  }
}
.champion > .title {
  margin: 1rem 0rem;
  height: 4.5rem;
  color: #ff901d;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  @media (min-width: 1600px) {
    font-size: 22px;
  }
}

.flex2 {
  margin-bottom: 1rem;
  justify-content: space-around;
  display: flex;
  width: 100%;
}
.items {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #333333;
  @media (min-width: 1600px) {
    font-size: 22px;
  }
  .row {
    font-weight: bold;
  }
}

.list_button {
  text-align: center;
}
.primaryThemeButton {
  background: #ffffff;
  border: 0.1rem solid #707070;
  border-radius: 2rem;
  opacity: 1;
  color: #333333;
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 3rem;
  @media (min-width: 1600px) {
    font-size: 20px;
    height: 3.6rem;
    padding: 0 3.6rem;;
  }
}
.points {
  position: relative;
}
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1.6rem;
  margin-top: -2.2rem;
  color: black;
  font-weight: bold;
  font-size: 1.8rem;
  transition: 0.6s ease;
  border-radius: 0 0.3rem 0.3rem 0;
  user-select: none;
}
.prev {
  left: 0;
  border-radius: 0.3rem 0 0 0.3rem;
}
.next {
  right: 0;
  border-radius: 0.3rem 0 0 0.3rem;
}
.prev:hover {
  background: transparent linear-gradient(270deg, #ffffff00 0%, #808080 100%) 0%
    0% no-repeat padding-box;
  border-radius: 1.6rem 0px 0px 1.7rem;
  opacity: 0.16;
}
.next:hover {
  background: transparent linear-gradient(90deg, #ffffff00 0%, #808080 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px 1.6rem 1.7rem 0px;
  opacity: 0.16;
}
@media screen and (max-width: 768px) {
  .next,
  .prev {
    padding: 0.5rem;
  }
}
@media screen and (max-width: 768px) {
  .next,
  .prev {
    padding: 0.5rem;
  }
}
@media screen and (max-width: 600px) {
  .LeaderboardModal {
    overflow: auto;
    justify-content: unset;
  }
  .champion {
    margin: 0rem 1rem;
    min-width: 22rem;
    overflow-y: hidden;
  }
  .next,
  .prev {
    display: none;
  }
}
