*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 10px;
}
@media (min-width: 1024px) {
  html {
    font-size: 7.49px;
  }
}
@media (min-width: 1152px) {
  html {
    font-size: 8.43px;
  }
}
@media (min-width: 1199px) {
  html {
    font-size: 8.77px;
  }
}
@media (min-width: 1280px) {
  html {
    font-size: 9.37px;
  }
}
@media (min-width: 1344px) {
  html {
    font-size: 9.83px;
  }
}
@media (min-width: 1366px) {
  html {
    font-size: 10px;
  }
}
@media (min-width: 1400px) {
  html {
    font-size: 10.24px;
  }
}
@media (min-width: 1440px) {
  html {
    font-size: 10.54px;
  }
}
@media (min-width: 1536px) {
  html {
    font-size: 11.24px;
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 11.77px;
  }
}
@media (min-width: 1680px) {
  html {
    font-size: 12.29px;
  }
}
@media (min-width: 1728px) {
  html {
    font-size: 12.65px;
  }
}
@media (min-width: 1856px) {
  html {
    font-size: 13.58px;
  }
}
@media (min-width: 1920px) {
  html {
    font-size: 14.05px;
  }
}
@media (min-width: 2448px) {
  html {
    font-size: 17.92px;
  }
}
@media (min-width: 2960px) {
  html {
    font-size: 21.66px;
  }
}
@media (min-width: 3840px) {
  html {
    font-size: 28.11px;
  }
}
@media (min-width: 7680px) {
  html {
    font-size: 56.22px;
  }
}
body {
  font-family: "proxima-nova", sans-serif;
  box-sizing: border-box;
  font-size: 1.5rem;
}
// body {
//     margin: 0;
//     padding: 0;
//     max-width: 100vw;
//     font-family: sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }
//   html {
//     overflow-x: hidden;
//   }

//   code {
//     font-family: "Proxima Nova", source-code-pro, Menlo, Monaco, Consolas,
//       "Courier New", monospace;
//   }

/* 
  "Proxima Nova Regular", -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", */

@mixin breakpoint($size) {
  @if $size == mobile {
    @media only screen and (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait {
    @media only screen and(min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape {
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @else if $size == desktop {
    @media only screen and (min-width: 992px) {
      @content;
    }
  } @else if $size == big-desktop {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
}
