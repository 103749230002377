.switch {
    position: relative;
    display: inline-block;
    width: 59px;
    height: 25px;
    @media (max-width: 600px) {
      width: 50px;
    }
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #BFBFBF;
    opacity: 1;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 2px;
    bottom: 2px;
    background-color: #BFBFBF;
    -webkit-transition: .4s;
    transition: .4s;
    @media (max-width: 600px) {
      width: 15px;
      height: 15px;
      bottom: 4px;
    }
  }
  
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    background-color: #43A60A;
    -webkit-transform: translateX(34px);
    -ms-transform: translateX(34px);
    transform: translateX(34px);
    @media (max-width: 600px) {
      transform: translateX(28px);
    }
  }
  
  /*------ ADDED CSS ---------*/
  .on
  {
    display: none;
    color: #43A60A;
    font-weight: bold;
  }
  
  .on
  {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 35%;
    font-size: 14px;
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  .off
  {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 60%;
    font-size: 14px;
    font-weight: bold;
    color: #bfbfbf;
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
  
  input:checked+ .slider .on
  {display: block;}
  
  input:checked + .slider .off
  {display: none;}
  
  /*--------- END --------*/
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 50px;
  }
  
  .slider.round:before {
    border-radius: 50%;}

    html[data-language="zh"], html[data-language="ja"], html[data-language="ko"], html[data-language="es"], html[data-language="pt"], html[data-language="fr"] {
      input:checked + .slider:before{
        transform: translateX(70px);
      }
    }
    html[data-language="es"],html[data-language="pt"]{
      input:checked + .slider:before{
        transform: translateX(80px);
      }
    }
    html[data-language="fr"]{
      input:checked + .slider:before{
        transform: translateX(74px);
      }
    }
    html[data-language="fr"]{
      .off{
        left: 80%;
        width: 100%;
      }
    }
    html[data-language="es"], html[data-language="fr"]{
      .on{
        left: 18%;
      }
    }