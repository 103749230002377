.homeNavbarFilter {
  position: absolute;
  top: 100%;
  right: 0%;
  //   height: 47rem;
  width: 24.1rem;
  padding: 1.5rem;
  background-color: #fff;
  border: 0.1rem solid #e8e8e8;
  box-shadow: 0rem 0.5rem 1rem #00000017;
  color: #333333;
  @media (max-width: 600px) {
    animation: drop 0.5s ease forwards;
    top: 5.7rem;
    right: 10px;
    
    @keyframes drop {
      0% {
      }
      70% {
        transform: translateY(10px);
      }
      100% {
        transform: translateY(0);
      }
    }
    + .homeNavbarOpaqueLayer::after {
      content: "";
      position: fixed;
      background-color: rgba(0, 0, 0, 0.37);
      width: 100%;
      top: 53px;
      height: 100%;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__heading {
      letter-spacing: 0px;
      font-size: 1.6rem;
      line-height: 2rem;
    }
    &__reset {
      letter-spacing: 0px;
      font-size: 1.4rem;
      line-height: 1.7rem;
      cursor: pointer;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      img {
        display: flex;
        align-items: center;
        margin-right: 0.6rem;
      }
    }
  }
  &__sortBy,
  &__sortByStatus {
    margin: 1.5rem 0rem;
    &__heading {
      letter-spacing: 0px;
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: bold;
      margin-bottom: 1.1rem;
    }
    &__sortList {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      &__item {
        flex: 1 1 100%;
        max-width: 50%;
        margin-bottom: 0.9rem;
        label {
          font-size: 1.4rem;
          padding-left: 2.6rem;
          padding-right: 0.3rem;
        }
      }
    }
  }

  &__filterByType {
    margin: 1.5rem 0rem;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1rem 0rem;
      &__heading {
        letter-spacing: 0px;
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
      &__search {
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        max-width: 11.8rem;
        flex: 1 1 100%;
        img {
          position: absolute;
          left: 1rem;
          width: 1.6rem;
          @media (max-width: 600px) {
            height: auto;
          }
        }
        input {
          width: 100%;
          border: 0.1rem solid #cbcbcb;
          border-radius: 3rem;
          height: 3rem;
          padding-left: 3rem;
          padding-right: 1.5rem;
          font-size: 1.2rem;
          outline: none;
          color: #707070;
          &::placeholder {
            color: #70707075;
          }
        }
      }
    }
    &__filterList {
      max-height: 17rem;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 1rem;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: #ececec;
      }
      &__item {
        margin-bottom: 0.9rem;

        label {
          font-size: 1.4rem;
          padding-left: 2.6rem;
          padding-right: 0.3rem;
        }
      }
    }
  }
}

html[dir="rtl"] .homeNavbarFilter {
  right: -90%;
  @media only screen and (max-width: 768px) {
    right: 0;
  }
  &__sortBy,
  &__sortByStatus {
    &__sortList {
      &__item {
        text-align: left;
      }
    }
  }
  &__filterByType {
    &__filterList {
      &__item {
        padding-left: 1rem;
      }
    }
  }

  &__header {
    &__reset {
      img {
        margin-right: 0rem;
        margin-left: 0.6rem;
      }
    }
  }
  &__filterByType {
    &__filterList {
      &__item {
        label{
          padding-right: 2.6rem;
          padding-left: 0.3rem;

        }
        span {
          left: initial;
          right: 0;
        }
      }
    }
  }
}
