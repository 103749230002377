.homeHeaderLinks {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  &__logo {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0rem 2rem;
    @media (min-width: 1600px) {
      margin: 0rem 1.5rem;
    }
    &__magnifyingGlass {
      display: flex;
      border: 0.1rem solid #bcbcbc;
      border-radius: 2.3rem;
      padding: 0.8rem;
      @media (min-width: 1600px) {
        padding: 0.7rem;
      }
    }
    &__worldLang {
      font-size: 1.8rem;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      svg {
        margin-top: 0.4rem;
        height: 2.8rem;
        @media (min-width: 1600px) {
          height: auto;
        }
      }
      @media (min-width: 1600px) {
        font-size: 1.2rem;
      }
    }
    &__leaderBoardScore {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      img {
        height: 3.5rem;
        @media (min-width: 1600px) {
          height: auto;
        }
      }
      &__score {
        font-size: 1.4rem;
        background-color: #ffd00d;
        border-radius: 3rem;
        padding: 0rem 1.6rem;
        @media (min-width: 1600px) {
          font-size: 14px;
        }
      }
    }
    &__profile {
      display: flex;
      position: relative;
      color: #fff;
      &__profileImage {
        border: 0.2rem solid var(--color-PrimaryColor1);
        border-radius: 100%;
        width: 5.5rem;
        height: 5.5rem;
        @media (min-width: 1600px) {
          width: 56px;
          height: 56px;
        }
        @media (max-width: 600px) {
          width: 100%;
          right: 0;
        }
      }
      &__profileImageDark {
        border: 0.2rem solid #fff;
      }
      &__notificationCount {
        width: 2.5rem;
        height: 2.5rem;
        // padding: 0.2rem;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eb0404 0% 0% no-repeat padding-box;
        position: absolute;
        border-radius: 100%;
        top: 0;
        left: -5px;
        @media (min-width: 1600px) {
          min-width: 10px;
          max-width: 30px;
          min-height: 10px;
          max-height: 30px;
          font-size: 12px;
          top: 2px;
          left: 2px;
        }
      }
      &__notificationCountDark {
        background: #fff 0% 0% no-repeat padding-box;
        color: var(--color-PrimaryColor1);
      }
    }
  }
  .homeHeader_searchContainer {
    position: relative;
    @media (max-width: 600px) {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;
      top: 5px;
    }
    .homeHeader_searchbar {
      display: flex;
      width: 52.6rem;
      // height: 5rem;
      height: 100%;
      align-items: center;
      border: 0.1rem solid #bcbcbc;
      border-radius: 5rem;
      @media (max-width: 600px) {
        width: 98%;
        margin: auto;
      }
      .switchIcon {
        display: flex;
        padding: 0 1.5rem;
        padding-right: 1.05rem;
        align-items: center;
        border-right: 0.1rem solid #bcbcbc;
        letter-spacing: 0px;
        color: #404040;
        font-size: 1.4rem;
        line-height: 1.7rem;
        label {
          margin-left: 0.6rem;
        }
        @media (min-width: 1600px) {
          font-size: 16px;
        }
      }
      .switchIconDark {
        color: #fff;
      }
      .input_box {
        flex: 1 1 100%;
        max-width: 100%;
        position: relative;
        padding-left: 1.5rem;
        padding-right: 3rem;
        color: #404040;
        &__input {
          width: 100%;
          // height: 4.8rem;
          height: 100%;
          background-color: transparent;
          border: none;
          outline: none;
          font-size: 1.4rem;
          line-height: 1.7rem;
          &::placeholder {
            color: rgba(112, 112, 112, 0.41);
          }
          @media (min-width: 1600px) {
            height: auto;
            font-size: 16px;
          }
        }
        &__inputDark {
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }

        .remove_search {
          background: transparent;
          border: none;
          position: absolute;
          padding: 0.8rem;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          svg {
            width: 1.4rem;
            height: 1.4rem;
            @media (min-width: 1600px) {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }
      }
      .icon_box {
        height: 4.8rem;
        border-left: 1px solid #bcbcbc;
        @media (max-width: 600px) {
          height: 4rem;
        }
        .searchIcon {
          height: 100%;
          padding-left: 0.9rem;
          padding-right: 1.2rem;
          background: transparent;
          border: none;
          cursor: pointer;
          img {
            width: 2.2rem;
            height: 2.2rem;
            @media (min-width: 1600px) {
              width: 1.6rem;
              height: 1.6rem;
            }
          }
        }
        @media (min-width: 1600px) {
          height: 100%;
        }
      }
      @media (min-width: 1600px) {
        height: 3.4rem;
      }
    }
    .relatedResult {
      position: absolute;
      padding: 2.2rem 2.9rem;
      background: #ffffff;
      box-shadow: 0px 0.3rem 0.6rem #00000029;
      border-radius: 0rem 0rem 3rem 3rem;
      width: 90%;
      max-height: 30rem;
      overflow-y: auto;
      left: 5%;
      top: 100%;
      min-height: 8rem;
      text-align: left;
      .result_item {
        .result_item_text {
          font-size: 1.5rem;
          line-height: 2.7rem;
          cursor: pointer; //903 hand symobol
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .homeHeaderLinks {
    &__logo {
      margin: 0rem 1.6rem;
    }
  }
}
@media only screen and (max-width: 768px) {
  .homeHeaderLinks {
    &__logo {
      margin: 0rem 1.2rem;
      &__magnifyingGlass {
        padding: 0.8rem;
        svg {
          height: 1.8rem;
        }
      }
      // &__worldLangContainer {
      //   display: none;
      // }
      &__worldLang {
        font-size: 1.5rem;

        svg {
          margin-top: 0.2rem;
          height: 2.3rem;
        }
      }
      &__leaderBoardScore {
        img {
          height: 3rem;
        }
        &__score {
          font-size: 1rem;
          padding: 0rem 1.4rem;
        }
      }
      &__profile {
        &__profileImage {
          width: 4.1rem;
          height: 4.1rem;
        }
        &__notificationCount {
          width: 1.9rem;
          height: 1.9rem;
          font-size: 1.1rem;
          // padding: 0.15rem;
        }
      }
    }
  }
  .homeHeaderLinks {
    &__logo {
      margin: 0rem 0.8rem;
      &__magnifyingGlass {
        padding: 0.6rem;
        svg {
          height: 1.8rem;
        }
      }
      &__worldLangContainer {
        display: none;
      }
      &__leaderBoardScore {
        img {
          height: 2.5rem;
        }
      }
      &__profile {
        &__notificationCount {
          // padding: 0.15rem;
          width: 1.7rem;
          height: 1.7rem;
          font-size: 1rem;
        }
      }
    }
  }
}
@media (min-width: 1600px) {
  .HomeHeaderLinks_homeHeaderLinks__logo__leaderBoardScore__score {
    font-size: 1.2rem;
  }
}

html[dir="rtl"] {
  .homeHeaderLinks {
    .homeHeader_searchContainer {
      .homeHeader_searchbar {
        .icon_box {
          border-right: 1px solid #bcbcbc;
          border-left: initial;
        }
        .switchIcon {
          label {
            margin-right: 0.6rem;
            margin-left: 0rem;
          }
        }
      }
      .relatedResult{
        text-align: right;
      }
      .homeHeader_searchbar {
        .switchIcon {
          border-left: 0.1rem solid #bcbcbc;
          border-right: initial;
        }
      }
    }
  }
}

html[data-language="zh"],
html[data-language="ja"],
html[data-language="ko"],
html[data-language="es"],
html[data-language="fr"],
html[data-language="pt"] {
  .homeHeaderLinks {
    .homeHeader_searchContainer {
      .homeHeader_searchbar {
        .input_box {
          flex: 1 1 56%;
        }
        .switchIcon {
          label {
            width: 100px;
          }
        }
      }
    }
  }
}
html[data-language="es"],
html[data-language="pt"] {
  .homeHeaderLinks {
    .homeHeader_searchContainer {
      .homeHeader_searchbar {
        .switchIcon {
          label {
            width: 110px;
          }
        }
      }
    }
  }
}
