.homeHeader {
  height: 7rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.3rem 1rem #0000000f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.8rem;
  &__logo {
    display: flex;
    align-items: center;
    // justify-content: center;
    margin: 0rem 2rem;
    &__lightHouseLinkContainer {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0rem 2rem;
      gap: 2rem;
    }
    &__link {
      // font-size: 1.8rem;
      // height: inherit;
      // display: flex;
      // // margin: 0rem 2rem;
      // flex-direction: column;
      // align-items: center;
      // // font-weight: bold;
      // color: #fff;
      // cursor: pointer;
      // text-decoration: none;
      // gap: 0.5rem;
      // justify-content: space-between;

      font-size: 1.8rem;
      height: inherit;
      display: flex;
      flex-direction: column;
      color: #fff;
      cursor: pointer;
      text-decoration: none;
      text-align: left;
      &__primary {
      }
      &__secondary {
        font-weight: bold;
        display: flex;
        font-size: 1rem;
        align-items: center;
        gap: 0.2rem;
        justify-content: center;
        white-space: nowrap;
        &__main {
          font-size: 1.3rem;
          color: #ffcc2a;
        }
      }
    }
    &__pipe {
      font-size: 2rem;
    }
    &__hamburgurMenuHeader {
      display: none;
    }
    &__mainLogo {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &__image {
        // min-height: 3rem;
        // min-width: 3rem;
        max-height: 4.5rem;
        object-fit: contain;
      }
    }
  }
  @media (min-width: 1600px) {
    height: 74px;
  }
}
.homeHeaderDark {
  background: var(--color-PrimaryColor1);
}

@media only screen and (max-width: 768px) {
  .homeHeader {
    padding: 0 2rem;
    height: 5.3rem;
    &__logo {
      margin: 0rem 1.4rem;
    }
  }
}
@media only screen and (max-width: 768px) {
  .homeHeader {
    padding: 0rem;
    &__logo {
      &__lightHouseLinkContainer {
        display: none;
      }
      // &__hamburgurMenuHeader {
      //   margin: 0rem 1rem;
      //   display: flex;
      // }
      &__mainLogo {
        img {
          height: 40px;
          // width: 5.6rem;
          width: auto;
          object-fit: initial;
        }
      }
      margin: 0rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .homeHeader {
    &__logo {
      &__hamburgurMenuHeader {
        margin: 0rem 1rem;
        display: flex;
      }
    }
  }
}

html[dir="rtl"] {
  .homeHeader {
    &__logo {
      &__link {
        text-align: right;
      }
    }
  }
}
