.modal_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(00, 00, 00, 0.5);
  cursor: default;
  .modal_dialog {
    //position: relative;
    position: absolute;
    width: auto;
    // margin: 0.5rem;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 600px) {
      width: 95%;
    }
    @media (min-width: 600px) {
      width: 550px;
      // margin: 1.75rem auto;
      // margin-top: 4rem;
    }
    @media (min-width: 1600px) {
      width: 800px;
    }
    @media (max-width: 576px) {
      // margin: 0.5rem 1.75rem;
      // margin-top: 4rem;
      margin: 0;
    }
    .modal_content {
      border: 0.1rem solid #707070;
      border-radius: 1.5rem;
      background: #fff;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;

      .modal_header {
        padding: 1.8rem 4.15rem;
        text-align: center;
        @media (max-width: 600px) {
          padding: 1.5rem 2rem;
        }
        .modal_title {
          margin: 0;
          letter-spacing: 0px;
          color: #333333;
          line-height: 3rem;
          font-size: 2.5rem;
          @media (min-width: 1600px) {
            font-size: 30px;
          }
          @media (max-width: 600px) {
            color: #000000;
            font-size: 2rem;
          }
        }
        .modal_close {
          cursor: pointer;
          position: absolute;
          top: 2.4rem;
          right: 1.8rem;
          font-size: 3.5rem;
          color: #333333;
          line-height: 2rem;
          background: transparent;
          border: none;
          font-family: inherit;
          @media (max-width: 600px) {
            right: 2rem;
            top: 2rem;
          }
          &:hover {
            color: var(--color-PrimaryColor1);
          }
        }
      }
      .modal_body {
        padding: 1.8rem 4.15rem;
        @media (max-width: 600px) {
          padding: 1.5rem 2rem;
        }
      }
      .modal_footer {
        padding: 3.4rem 4.15rem;
        text-align: right;
        @media (max-width: 768px) {
          padding: 1.8rem 2.4rem;
        }
        &.addContributeModal {
          padding: 3.4rem 0;
        }
      }
    }
  }
  &.modal_add_query {
    .modal_dialog {
      // @media (min-width: 576px) {
      //   width: 800px;
      // }
      .modal_content {
        @media (max-width: 768px) {
          background-color: #f6f6f6;
        }
        .modal_body {
          @media (max-width: 768px) {
            padding: 0 2.4rem;
          }
        }
      }
    }
  }
  &.modal_submit_success {
    .modal_dialog {
      @media (min-width: 576px) {
        max-width: 58rem;
      }
      .modal_content {
        .modal_body {
          padding-top: 0.9rem;
          @media (max-width: 768px) {
            padding-bottom: 2.2rem;
          }
        }
      }
    }
  }
  &.modal_contribute {
    .modal_dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      .modal_content {
        @media (max-width: 600px) {
          // width: 34rem;
        }
        .modal_footer {
          @media (max-width: 600px) {
            padding: 2rem 0rem;
          }
        }
      }
    }
  }

  &.modal_contribute_upload {
    .modal_dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 576px) {
        max-width: 100%;
        margin-top: 4rem;
        width: 800px;
      }
      .modal_content {
        width: 108.2rem;
        @media (max-width: 600px) {
          // width: 34rem;
          height: 47.7rem;
          width: 100%;
        }
        .modal_body {
          @media (max-width: 600px) {
            padding: 0rem;
          }
          .modal_footer {
            @media (max-width: 600px) {
              padding: 2rem 0rem;
            }
          }
        }
      }
    }
  }
  // &.modal_leader_board {
  //   // display: flex;
  //   // align-items: center;
  //   // justify-content: center;
  //   // .modal_dialog {
  //   //   // display: flex;
  //   //   // align-items: center;
  //   //   // justify-content: center;
  //   //   // min-width: 58rem;
  //   //   // margin: 0;
  //   //   .modal_content {
  //   //     // width: 58rem;
  //   //     @media screen and (max-width: 768px) {
  //   //       width: 55rem;
  //   //       margin-top: 4rem;
  //   //     }
  //   //     @media screen and (max-width: 600px) {
  //   //       width: 30rem;
  //   //     }
  //   //   }
  //   // }
  // }
  &.modal_credits_popup {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .modal_dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 58rem;
      margin: 0;
      .modal_content {
        .modal_body {
          position: relative;
        }
        .modal_title {
          padding-top: 2rem;
          font-size: 5.5rem;
          color: #333333;
        }
        width: 58rem;
        @media screen and (max-width: 768px) {
          width: 55rem;
          margin-top: 4rem;
        }
        @media screen and (max-width: 600px) {
          width: 30rem;
        }
      }
    }
  }
  &.modal_noFavorites {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .modal_dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      .modal_content {
        width: 46rem;
        height: 27rem;
        border-radius: 1rem;
        .modal_body {
          height: 100%;
        }
      }
    }
  }
  &.modal_classComplete {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .modal_dialog {
      display: flex;
      align-items: center;
      justify-content: center;

      .modal_content {
        width: 46rem;
        height: 27rem;
        border-radius: 1rem;
        .modal_body {
          height: 100%;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
  &.modal_seriesCerificate {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .modal_dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      .modal_content {
        width: 60vw;
        height: 90vh;
        @media (max-width: 768px) {
          width: 100%;
        }
        border-radius: 0rem;
        .modal_body {
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }
    }
  }
  &.modal_add_query_class {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .modal_dialog {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // margin: 0;
      .modal_content {
        // width: 58rem;
        // height: 40rem;
        .modal_body {
          height: 100%;
          width: 100%;
        }
        .modal_footer {
          padding: 2.4rem 4.15rem;
          @media (max-width: 600px) {
            padding: 1.5rem 2rem;
          }
        }
      }
    }
  }
  &.modal_view_attachment {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .modal_dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      .modal_content {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        border-radius: 0%;
        height: 50vh;
        width: 50vw;
        background-color: #fff;
        @media (max-width: 600px) {
          width: 95%;
        }
        .modal_close {
          top: 5%;
          right: 5%;
          cursor: pointer;
          position: absolute;
          font-size: 3.5rem;
          color: #333333;
          line-height: 2rem;
          background: transparent;
          border: none;
          font-family: inherit;
          @media (max-width: 600px) {
            right: 1rem;
            background: #fff;
            border-radius: 50%;
            display: flex;
            padding: 5px;
            top: 1rem;
          }
          &:hover {
            color: var(--color-PrimaryColor1);
          }
        }
        .modal_body {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
        }
      }
    }
  }
  &.quiz_modal_view_attachment {
    // display: flex;
    // align-items: center;
    // justify-content: center;

    .modal_dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      .modal_content {
        animation: fadeIn 300ms ease;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        border-radius: 0%;
        height: 50vh;
        width: 50vw;
        @media (max-width: 600px) {
          width: 95%;
          // height: auto;
          border-radius: 4px;
          padding: 10px;
        }
        background-color: #fff;
        .modal_close {
          top: 5%;
          right: 5%;
          cursor: pointer;
          position: absolute;
          font-size: 3.5rem;
          color: #333333;
          line-height: 2rem;
          background: transparent;
          border: none;
          font-family: inherit;
          @media (max-width: 600px) {
            right: 1.1rem;
            background-color: #fff;
            border-radius: 50%;
            padding: 2px;
            width: 24px;
            height: 24px;
            top: 11px;
          }
          &:hover {
            color: var(--color-PrimaryColor1);
          }
        }
        .modal_body {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          @media (max-width: 600px) {
          }
        }
      }
    }
  }
  &.modal_extendTimer {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    z-index: 1051;
    .modal_dialog {
      display: flex;
      align-items: center;
      justify-content: center;

      .modal_content {
        width: 46rem;
        height: 27rem;
        border-radius: 1rem;
        .modal_body {
          height: 100%;
        }
      }
    }
  }
  &.modal_sessionExpired {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .modal_dialog {
      // display: flex;
      // align-items: center;
      // justify-content: center;

      .modal_content {
        width: 40rem;
        height: 20rem;
        border-radius: 1rem;
        margin: auto;
        @media (max-width: 600px) {
          width: 100%;
        }
        .modal_body {
          height: 100%;
        }
      }
    }
  }
  &.modal_privacy_policy {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .modal_dialog {
      width: 95%;
    }
    .modal_content {
      // width: 127rem;
      // max-width: 100%;
      // height: 90vh;
      .modal_header {
        // height: 15%;
        text-align: left;
      }
      .modal_body {
        // height: 75%;
      }
      .modal_footer {
        height: 15%;
        padding: 1rem 4.15rem;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

html[dir="rtl"] {
  .modal_wrapper {
    .modal_dialog {
      .modal_content {
        .modal_header {
          .modal_close {
            right: initial;
            left: 1.8rem;
          }
        }
        .modal_footer {
          text-align: left;
          direction: ltr;
        }
      }
    }
  }
}
