.custom-radio {
	display: block;
	position: relative;
	padding-left: 2.8rem;
	padding-right: 0.5rem;
	cursor: pointer;
	font-size: 1.5rem;
	line-height: 2rem;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:focus~.checkmark {
			border: .2rem solid $color-PrimaryColor1;
		}
		&:checked~.checkmark {
			background-color: #fff;
			// border: .2rem solid #707070;
			&:after {
				display: block;
			}
		}
	}
	.checkmark {
		position: absolute;
		top: .0rem;
		left: 0;
		height: 2rem;
		width: 2rem;
		// background-color: transparent;
		background-color: #ffffff;
		border: .1rem solid #AEAEAE;;
		border-radius: 50%;
		box-sizing: border-box;
		color: #404040;
		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 50%;
			top: 50%;
			width: 1rem;
			height: 1rem;
			border: solid #4A4040;
			border-width: 0.2rem 0.2rem 0.2rem 0.2rem;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background-color: #4A4040;
		}
	}
	&:hover {
		input~.checkmark {
			border: .2rem solid $color-PrimaryColor1;
		}
	}
}
