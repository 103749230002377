.pollResult {
  .chatBotRow {
    display: flex;
    height: calc(100vh - 12.6rem);
    @media (min-width: 1600px) {
      height: calc(100vh - 9.3rem);
    }
    @media (max-width: 768px) {
      height: auto;
      flex-direction: column;
      position: relative;
      padding-bottom: 3rem;
    }
    .chatLeftListArea {
      max-width: 38.1rem;
      flex: 1 1 100%;
      border-right: 0.1rem solid #d8d8d8;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        max-width: 100%;
        margin-bottom: 4rem;
      }
      .listActionHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.6rem;
        padding-top: 3.06rem;
        padding-bottom: 2.13rem;
        @media (max-width: 600px) {
          padding: 2rem;
          align-items: flex-start;
        }
        @media (max-width: 370px) {
          // flex-direction: column;
          // align-items: flex-start;
        }
        .heading {
          letter-spacing: 0px;
          color: #404040;
          font-size: 2rem;
          line-height: 2.4rem;
          margin-left: 1.5rem;
          @media (min-width: 1600px) {
            font-size: 26px;
          }
          @media (max-width: 600px) {
            margin: 0;
          }
          // @media (max-width: 370px) {
          //   margin-bottom: 1.5rem;
          // }
        }
        .tabList_box {
          display: none;
          // @media (max-width: 370px) {
          //   max-width: 100%;
          //   margin-bottom: 1.5rem;
          // }
          ul.tabList {
            li {
              display: inline-block;
              cursor: pointer;
              letter-spacing: 0px;
              color: #707070;
              font-size: 1.8rem;
              line-height: 2.2rem;
              padding-bottom: 0.5rem;
              border-bottom: 0.3rem solid transparent;
              @media (min-width: 1600px) {
                font-size: 22px;
              }
              &:not(:first-child) {
                margin-left: 3rem;
                @media (max-width: 600px) {
                  margin-left: 1rem;
                }
              }
              &.tab_active {
                font-weight: bold;
                border-bottom: 0.3rem solid var(--color-PrimaryColor1);
                color: var(--color-PrimaryColor1);
              }
            }
          }
        }
        .action_box {
          display: flex;
          align-items: center;
          @media (max-width: 370px) {
            margin-left: 1.5rem;
          }
          .btn_delete {
            font-size: 1.5rem;
            color: #404040;
            padding: 0.4rem 0.65rem;
            background: transparent;
            border: none;
            margin-left: 1.3rem;
            cursor: not-allowed;
            svg {
              vertical-align: middle;
              width: 2.2rem;
            }
            .text_label {
              margin-left: 0.61rem;
            }
            &.active {
              cursor: pointer;
              svg {
                fill: var(--color-PrimaryColor1);
              }
            }
          }
        }
      }
      .chatListArea {
        overflow-y: auto;
        flex: 1 1 100%;
        position: relative;
        // @media (max-width: 768px) {
        //   max-height: 30rem;
        //   min-height: 20rem;
        // }
        .chatList {
          .noThread {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            letter-spacing: 0px;
            color: #000000;
            opacity: 0.3;
            font-size: 2.3rem;
            font-weight: bold;
            line-height: 2.8rem;
            text-transform: uppercase;
            .noResultImg{
              display: none;
            }
            @media (max-width: 768px) {
              position: inherit;
              transform: inherit;
              opacity: 1;
              margin-top: 10rem;
              display: flex;
              justify-content: center;
              .noResultImg {
                height: 100%;
                opacity: 1;
                display: flex;
              }
              .noResult {
                display: none;
              }
            }
          }
          .chatListItemContainer {
            display: flex;
            flex-direction: column;
            // align-items: center;
            // width: 100%;
          }
          .chatListItem {
            padding: 0 1.5rem;
            padding-top: 2.2rem;
            padding-bottom: 0.93rem;
            border-bottom: 0.1rem solid #d8d8d8;
            position: relative;
            cursor: pointer;
            transition: all 0.3s;
            display: flex;
            align-items: center;
            &:before {
              content: "";
              position: absolute;
              height: 2.7rem;
              width: 0.3rem;
              background: #d8d8d8;
              border-radius: 1rem;
              display: inline-block;
              top: 50%;
              left: 1rem;
              transform: translateY(-50%);
              transition: all 0.3s;
            }
            .missed_icon {
              position: absolute;
              top: -4rem;
              right: 0.8rem;
              line-height: 1;
              svg {
                height: 0.817rem;
                width: 1.6rem;
              }
            }
            .item_avatar {
              padding-left: 1.2rem;
              margin-right: -0.5rem;
              .item_avatarImage {
                width: 6rem;
                height: 6rem;
                border-radius: 100%;
                object-fit: cover;
              }
            }
            .chatContent {
              flex: 1 1 100%;
              .chatItemTitle {
                letter-spacing: 0px;
                color: #404040;
                font-size: 1.5rem;
                line-height: 1.8rem;
                padding-right: 0.2rem;
                padding-left: 1.8rem;
                margin-bottom: 1.46rem;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                transition: all 0.3s;
                word-break: break-word;
              }
              .chatItemFooter {
                letter-spacing: 0px;
                color: #707070;
                font-size: 1.2rem;
                line-height: 1.5rem;
                text-align: right;
                transition: all 0.3s;
                .author {
                  color: #4a4a4a;
                }
                .date {
                }
                .time {
                  margin-left: 2.2rem;
                }
              }
            }
            &.active,
            &:hover {
              background: #ebebeb;
              border-bottom: 0.1rem solid #ebebeb;
              transition: all 0.3s;
              &:before {
                background: var(--color-PrimaryColor1);
                transition: all 0.3s;
              }
              .item_avatar {
                .item_avatarImage {
                }
              }
              .chatContent {
                .chatItemTitle {
                  font-weight: bold;
                  transition: all 0.3s;
                }
                .chatItemFooter {
                  font-weight: bold;
                  transition: all 0.3s;
                  .date {
                  }
                  .time {
                  }
                }
              }
            }
          }
        }
      }
      .chatLeftFooter {
        background: #ebebeb;
        letter-spacing: 0px;
        color: #404040;
        font-size: 1.2rem;
        line-height: 1.5rem;
        padding: 0.7rem 1.05rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media (max-width: 768px) {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
        }
        .lastVisited {
          span {
          }
        }
        .totalQueries {
          span {
          }
        }
      }
    }
  }
}

.polltooltiptext {
  width: 10rem;
  background-color: #404040;
  color: #fff;
  text-align: center;
  border-radius: 0.6rem;
  padding: 0.2rem 0;
  // position: absolute;
  z-index: 1;
  //left: 100%;
  // top: 0%;
  // left: 150%;
  font-size: 13px;
  padding: 3px 10px;
  position: absolute;
  margin-left: 1.5rem;
  display: inline-block;
  margin-left: -17vh;
  // margin-left: 0;
  top: 0rem;
  @media (min-width: 1600px) {
    width: 7rem;
    margin-left: -12vh;
  }
  @media (max-width: 600px) {
    position: inherit;
    left: -20px;
    display: block;
    width: 10rem;
    top: 5px;
    margin: 10px 0;
  }
}
@media (max-width: 600px) {
  .youVoted {
    .polltooltiptext {
      display: none;
    }
  }
}
.polltooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -17px;
  // margin-top: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #404040;
  transform: translateY(-50%);
  @media (min-width: 1600px) {
    // right: 98%;
  }
  @media (max-width: 600px) {
    border-color: transparent transparent #404040 transparent;
    top: -9px;
    right: initial;
    left: 14px;
  }
}
.polltooltiptext {
  visibility: visible;
}

.polltooltiptextNew {
  width: 10rem;
  background-color: #404040;
  color: #fff;
  text-align: center;
  border-radius: 0.6rem;
  padding: 0.2rem 0;
  font-size: 1.3rem;
  font-weight: normal;
  //position: absolute;
  z-index: 1;
  //left: 100%;
  //left: 100%;
  // top: 0%;
  // left: 150%;
  font-size: 13px;
  padding: 3px 10px;
  position: relative;
  margin-left: 1.5rem;
  @media (max-width: 600px) {
    display: block;
    width: 15rem;
    margin: 10px 0 0;
  }
}

.polltooltiptextNew::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 99%;
  // margin-top: -5px;
  border-width: 9px;
  border-style: solid;
  border-color: transparent #404040 transparent transparent;
  transform: translateY(-50%);
  @media (min-width: 1600px) {
    right: 98%;
  }
  @media (max-width: 600px) {
    top: -9px;
    right: 59px;
    border-color: transparent transparent #404040 transparent;
  }
}
.polltooltiptextNew {
  visibility: visible;
}

.chatRightTypeAreaMobile {
  flex: 1 1 100%;
  display: none;
  flex-direction: column;
  justify-content: space-between;

  .chatBotHeader {
    padding: 2.45rem 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 370px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .heading_box {
    }
    .tabList_box {
      @media (max-width: 370px) {
        max-width: 100%;
        margin-bottom: 1.5rem;
      }
      ul.tabList {
        li {
          display: inline-block;
          cursor: pointer;
          letter-spacing: 0px;
          color: #707070;
          font-size: 1.8rem;
          line-height: 2.2rem;
          padding-bottom: 0.5rem;
          border-bottom: 0.3rem solid transparent;
          @media (min-width: 1600px) {
            font-size: 22px;
          }
          &:not(:first-child) {
            margin-left: 3rem;
          }
          &.tab_active {
            font-weight: bold;
            border-bottom: 0.3rem solid var(--color-PrimaryColor1);
            color: var(--color-PrimaryColor1);
          }
        }
      }
    }
  }
  .pollResultDetail {
    overflow-y: auto;
    flex: 1 1 100%;
    .pollResultInner {
      // padding-left: 16.2rem;
      // padding-right: 4.5rem;
      padding: 0 4rem 2rem 10rem;
      @media (max-width: 1024px) {
        padding-left: 4.5rem;
      }
      @media (max-width: 600px) {
        padding: 2rem;
        transition: 0.5s all;
        -webkit-animation: fade-in2 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) both;
        animation: fade-in2 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) both;
        @keyframes fade-in2 {
          0% {
            transform: scaleY(0);
            visibility: hidden;
            opacity: 0;
          }
          100% {
            transform: scaleY(1);
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .title_row {
        margin-bottom: 2.26rem;
        word-break: break-all;
        padding: 0 30px;
        text-align: center;
        @media (max-width: 600px) {
          margin-bottom: 2.3rem;
          padding: 0;
          text-align: left;
        }
        h2 {
          letter-spacing: 0px;
          color: #404040;
          font-size: 1.8rem;
          line-height: 2.2rem;
          @media (max-width: 600px) {
            font-size: 1.5rem;
          }
        }
      }
      .chart_detail_row {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 991px) {
          display: block;
        }
        .chart_wrapper {
          max-width: 41rem;
          flex: 1 1 100%;
          height: 41rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          @media (max-width: 991px) {
            margin-bottom: 5.5rem;
          }
          @media (max-width: 600px) {
            height: 31.5rem;
            max-width: 31.5rem;
            margin: 0 auto 3.5rem;
          }
          @media (max-width: 370px) {
            height: 25.5rem;
            max-width: 25.5rem;
            margin-bottom: 3.5rem;
          }
          &::after {
            content: "";
            box-shadow: 0px 0.3rem 1rem #00000029;
            position: absolute;
            // height: 67.12%;
            // width: 67.12%;
            width: 260px;
            height: 260px;
            border-radius: 100%;
            opacity: 0.5;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .chart_box {
            width: 25rem;
            position: relative;
            z-index: 1;
            @media (max-width: 768px) {
              width: 12.1rem;
              height: 12.1rem;
            }
            .vote_count {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
              font-size: 1.8rem;
              line-height: 2.1rem;
              color: #1f78b4;
              z-index: -1;
              .count {
                font-weight: bold;
                display: block;
                font-size: 2.5rem;
                line-height: 2.1rem;
              }
            }
          }
        }
        .result_info {
          //max-width: 36.5rem;
          display: flex;
          flex-direction: column;
          padding-left: 6.5rem;
          @media (max-width: 991px) {
            max-width: unset;
          }
          @media (max-width: 600px) {
            padding: 0;
            text-align: left;
            padding-left: 6rem;
          }
          h3 {
            letter-spacing: 0px;
            color: #404040;
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin-bottom: 1.2rem;
            span {
              margin-left: 1.5rem;
            }
            @media (max-width: 600px) {
              span.polltooltiptextNew {
                margin-left: 0;
              }
            }
          }
          .legend_list {
            margin-bottom: 0.7rem;
            display: flex;
            flex-direction: column;
            .legend_item {
              position: relative;
              padding-left: 3.1rem;
              margin-bottom: 1.1rem;
              display: flex;
              .legend_color {
                height: 1.6rem;
                width: 1.6rem;
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0.3rem;
              }
              .legend_txt {
                letter-spacing: 0px;
                color: #404040;
                font-size: 1.5rem;
                line-height: 1.8rem;
                word-break: break-all;
                position: relative;
              }
            }
          }
          p {
            letter-spacing: 0px;
            color: #404040;
            font-size: 1.5rem;
            line-height: 1.8rem;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.chatRightTypeArea {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .chatBotHeader {
    padding: 2.45rem 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 370px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .heading_box {
    }
    .tabList_box {
      @media (max-width: 370px) {
        max-width: 100%;
        margin-bottom: 1.5rem;
      }
      ul.tabList {
        li {
          display: inline-block;
          cursor: pointer;
          letter-spacing: 0px;
          color: #707070;
          font-size: 1.8rem;
          line-height: 2.2rem;
          padding-bottom: 0.5rem;
          border-bottom: 0.3rem solid transparent;
          @media (min-width: 1600px) {
            font-size: 22px;
          }
          &:not(:first-child) {
            margin-left: 3rem;
          }
          &.tab_active {
            font-weight: bold;
            border-bottom: 0.3rem solid var(--color-PrimaryColor1);
            color: var(--color-PrimaryColor1);
          }
        }
      }
    }
  }
  .pollResultDetail {
    overflow-y: auto;
    flex: 1 1 100%;
    .pollResultInner {
      // padding-left: 16.2rem;
      // padding-right: 4.5rem;
      padding: 0 4rem 2rem 6rem;
      @media (max-width: 1024px) {
        padding-left: 4.5rem;
      }
      .title_row {
        margin-bottom: 2.26rem;
        word-break: break-all;
        padding: 0 30px;
        text-align: center;
        h2 {
          letter-spacing: 0px;
          color: #404040;
          font-size: 1.8rem;
          line-height: 2.2rem;
        }
      }
      .chart_detail_row {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 991px) {
          display: block;
        }
        .chart_wrapper {
          max-width: 41rem;
          flex: 1 1 100%;
          height: 41rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-right: 50px;
          @media (max-width: 991px) {
            margin-bottom: 5.5rem;
          }
          @media (max-width: 768px) {
            height: 31.5rem;
            max-width: 31.5rem;
          }
          @media (max-width: 370px) {
            height: 25.5rem;
            max-width: 25.5rem;
            margin-bottom: 3.5rem;
          }
          &::after {
            content: "";
            box-shadow: 0px 0.3rem 1rem #00000029;
            position: absolute;
            // height: 67.12%;
            // width: 67.12%;
            width: 230px;
            height: 230px;
            border-radius: 100%;
            opacity: 0.5;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .chart_box {
            width: 25rem;
            position: relative;
            z-index: 1;
            @media (max-width: 768px) {
              width: 12.1rem;
              height: 12.1rem;
            }
            .vote_count {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
              font-size: 1.8rem;
              line-height: 2.1rem;
              color: #1f78b4;
              z-index: -1;
              .count {
                font-weight: bold;
                display: block;
                font-size: 2.5rem;
                line-height: 2.1rem;
              }
            }
          }
        }
        .result_info {
          //max-width: 36.5rem;
          display: flex;
          flex-direction: column;
          padding-left: 5.5rem;
          width: 50%;
          @media (max-width: 991px) {
            max-width: unset;
          }
          @media (max-width: 768px) {
            padding-bottom: 2rem;
          }
          h3 {
            letter-spacing: 0px;
            color: #404040;
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin-bottom: 1.2rem;
            span {
              margin-left: 1.5rem;
            }
          }
          .legend_list {
            margin-bottom: 0.7rem;
            display: flex;
            flex-direction: column;
            .legend_item {
              position: relative;
              // padding-left: 3.1rem;
              margin-bottom: 1.1rem;
              display: flex;
              .youVoted {
                position: relative;
                width: 15px;
                height: 15px;
                margin-right: 3.1rem;

                @media (max-width: 600px) {
                  display: none;
                }
              }
              .legend_color {
                height: 1.6rem;
                width: 1.6rem;
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0.3rem;
              }
              .legend_txt {
                letter-spacing: 0px;
                color: #404040;
                font-size: 1.5rem;
                line-height: 1.8rem;
                word-break: break-all;
                position: relative;
                .polltooltiptext {
                  display: none;
                  @media (max-width: 600px) {
                    display: block;
                  }
                }
              }
            }
          }
          p {
            letter-spacing: 0px;
            color: #404040;
            font-size: 1.5rem;
            line-height: 1.8rem;
            font-weight: bold;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .pollResult {
    .chatBotRow {
      .chatLeftListArea {
        .listActionHeader {
          .tabList_box {
            display: block;
          }
        }
      }
    }
  }
  .chatRightTypeArea {
    display: none;
  }
  .chatRightTypeAreaMobile {
    display: flex;
    .chatBotHeader {
      display: none;
    }
  }
}

html[dir="rtl"] .chatRightTypeArea {
  .chatBotHeader {
    .tabList_box {
      ul.tabList {
        li {
          &:not(:first-child) {
            margin-right: 3rem;
          }
        }
      }
    }
  }
  .pollResultDetail {
    .pollResultInner {
      .chart_detail_row {
        .result_info {
          h3 {
            direction: rtl;
            text-align: left;
            span {
              margin-right: 1.5rem;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

html[dir="rtl"] {
  .chatBotRow {
    display: flex;
    height: calc(100vh - 12.6rem);
    @media (min-width: 1600px) {
      height: calc(100vh - 9.3rem);
    }
    @media (max-width: 768px) {
      height: auto;
      flex-direction: column;
      position: relative;
      padding-bottom: 3rem;
    }
    .chatLeftListArea {
      max-width: 38.1rem;
      flex: 1 1 100%;
      border-right: 0.1rem solid #d8d8d8;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        max-width: 100%;
        margin-bottom: 4rem;
      }
      .listActionHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.6rem;
        padding-top: 3.06rem;
        padding-bottom: 2.13rem;
        @media (max-width: 600px) {
          padding: 2rem;
          align-items: flex-start;
        }
        @media (max-width: 370px) {
          // flex-direction: column;
          // align-items: flex-start;
        }
        .heading {
          letter-spacing: 0px;
          color: #404040;
          font-size: 2rem;
          line-height: 2.4rem;
          margin-left: 1.5rem;
          @media (min-width: 1600px) {
            font-size: 26px;
          }
          @media (max-width: 600px) {
            margin: 0;
          }
          // @media (max-width: 370px) {
          //   margin-bottom: 1.5rem;
          // }
        }
        .tabList_box {
          display: none;
          // @media (max-width: 370px) {
          //   max-width: 100%;
          //   margin-bottom: 1.5rem;
          // }
          ul.tabList {
            li {
              display: inline-block;
              cursor: pointer;
              letter-spacing: 0px;
              color: #707070;
              font-size: 1.8rem;
              line-height: 2.2rem;
              padding-bottom: 0.5rem;
              border-bottom: 0.3rem solid transparent;
              @media (min-width: 1600px) {
                font-size: 22px;
              }
              &:not(:first-child) {
                margin-left: 3rem;
                @media (max-width: 600px) {
                  margin-left: 1rem;
                }
              }
              &.tab_active {
                font-weight: bold;
                border-bottom: 0.3rem solid var(--color-PrimaryColor1);
                color: var(--color-PrimaryColor1);
              }
            }
          }
        }
        .action_box {
          display: flex;
          align-items: center;
          @media (max-width: 370px) {
            margin-left: 1.5rem;
          }
          .btn_delete {
            font-size: 1.5rem;
            color: #404040;
            padding: 0.4rem 0.65rem;
            background: transparent;
            border: none;
            margin-left: 1.3rem;
            cursor: not-allowed;
            svg {
              vertical-align: middle;
              width: 2.2rem;
            }
            .text_label {
              margin-left: 0.61rem;
            }
            &.active {
              cursor: pointer;
              svg {
                fill: var(--color-PrimaryColor1);
              }
            }
          }
        }
      }
      .chatListArea {
        overflow-y: auto;
        flex: 1 1 100%;
        position: relative;
        // @media (max-width: 768px) {
        //   max-height: 30rem;
        //   min-height: 20rem;
        // }
        .chatList {
          .chatListItem {
            .chatContent {
              .chatItemFooter {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  .pollResultDetail {
    .pollResultInner {
      .chart_detail_row {
        .result_info {
          direction: ltr;
          width: 45%;
          p {
            direction: rtl;
            text-align: left;
          }
        }
      }
    }
  }
  .pollResult {
    .chatListArea {
      .chatList {
        .chatListItem {
          .missed_icon {
            right: initial !important;
            left: 0.8rem;
            // top: 0.2rem !important;
          }
        }
      }
    }
  }
  .polltooltiptextNew::after {
    right: initial;
    left: 98%;
    border-color: transparent transparent transparent #404040;
  }
}

html[data-language="fr"],
html[data-language="de"] {
  .pollResult {
    .chatRightTypeArea {
      .pollResultDetail {
        .pollResultInner {
          .chart_detail_row {
            .result_info {
              h3 {
                span {
                  span {
                    display: block;
                    width: 65%;
                    margin: 1rem 0 0;
                    &::after {
                      border-color: transparent transparent #404040 transparent;
                      top: -8px;
                      right: 40%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.tooltip {
  position: relative;
  display: flex;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 12.7rem;
  background-color: #404040;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -4.5rem;
  left: -10rem;
  transform: translateX(-50%);
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.5rem;
  padding: 0.6rem 0.6rem;
  @media (max-width: 600px) {
    width: 70px;
    font-size: 10px;
  }
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 30%;
  left: 100%;
  // margin-left: -5px;
  border-width: 0.5rem;
  border-style: solid;
  border-color: transparent transparent transparent #404040;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
@media only screen and (max-width: 600px) {
  .tooltip:hover .tooltiptext {
    width: 10rem;
    font-size: 1rem;
  }
}

html[dir="rtl"] {
  .tooltip .tooltiptext {
    left: initial;
    transform: translateX(-50%);
    right: -17rem;
    top: -3.5rem;
    transform: translate(0, -50%);
  }
  .tooltip .tooltiptext::after {
    border-color: transparent #404040 transparent transparent;
    left: initial;
    right: 100%;
  }
}
