.LeaderboardModal {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .title {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .title1 {
    display: none;
  }
}
