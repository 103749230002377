.pinkButton {
  border-radius: 2rem;
  cursor: pointer;
  font-weight: bold;
  height: 4rem;
  letter-spacing: 0px;
  background-color: transparent;
  font-size: 1.8rem;
  line-height: 2.2rem;
  padding: 0 4.6rem;
  font-family: inherit;
  border: 0.1rem solid var(--color-DefaultColor);
  color: var(--color-DefaultColor);
  &:disabled {
    cursor: not-allowed;
  }
  &:active {
    opacity: 0.9;
    transform: translateY(0.1rem);
  }
}
