:root {
  --color-DefaultColor: #d31b61;
  --color-BGColor: #d31b61;
  --color-PaneColor: #d31b61;
  --color-PrimaryColor1: #d31b61;
  --color-PrimaryColor2: #d31b61;
  --color-PrimaryColor3: #d31b61;
  --color-PrimaryColor4: #d31b61;
  --color-SecondaryColor: #d31b61;
  --color-TabDisable: #d31b61;
  --color-TabOnSelect: #d31b61;
  --color-TextColor: #d31b61;
}
.MuiTooltip-tooltip {
  font-size: 1rem !important;
}
body {
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  // display: grid;
  // grid-template-rows: 1fr auto;
}
.App {
  position: relative;
  min-height: 100vh;
}
