.quizContainerModal {
    width: 46.2rem;
    background-color: #fff;
    border: 1px solid #707070;
    border-radius: 1rem;
    position: relative;
    padding: 1.5rem;
    @media (max-width: 600px) {
        width: 95%;
    }
}
.quizContainerModal__closeButton {
    cursor: pointer;
    position: absolute;
    top: 2.2rem;
    right: 1.8rem;
    font-size: 3.5rem;
    color: #333333;
    line-height: 2rem;
    &:hover {
        color: var(--color-PrimaryColor1);
    }
}
.quizContainerModal__container {
    text-align: center;
}
.quizContainerModal__heading {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3rem;
    color: #333333;
    margin-bottom: 4.2rem;
}
.quizContainerModal__details {
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #404040;
    margin-bottom: 2.9rem;
    @media (min-width: 1600px) {
        font-size: 26px;
        line-height: 37px;
    }
    @media (max-width: 600px) {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}
.quizContainerModal__buttonAndLink {
    padding-bottom: 0%;
}
.primaryThemeButton {
    font-size: 1.8rem;
    line-height: 2.2rem;
    letter-spacing: 0px;
    color: #f4f4f4;
    padding: 0 5.85rem;
    height: 4.9rem;
    border-radius: 0.7rem;
    margin-bottom: 0.8rem;
    background-color: var(--color-PrimaryColor1);
    font-family: inherit;
    border: 0.1rem solid var(--color-PrimaryColor1);
    @media (min-width: 1600px) {
        font-size: 22px;
        height: 60px;
        padding: 0 40px;
    }
}
.quizContainerModal__link {
    color: var(--color-PrimaryColor1);
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
.quizContainer {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}
