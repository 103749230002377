.homeHeaderLanguage {
  position: absolute;
  top: 7rem;
  height: 32rem;
  width: 23rem;
  padding: 1rem 1.5rem 0.8rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 0rem 0.5rem 1rem #00000017;
  border: 0.1rem solid #e8e8e8;
  cursor: auto;
  background-color: #fff;
  z-index: 99;
  @media (min-width: 1600px) {
    top: 5.3rem;
  }
  @media (max-width: 600px) {
    box-shadow: initial;
    border-bottom: initial;
    border-right: initial;
    border-left: initial;
    background-color: transparent;
    position: initial;
    width: 100%;
    padding: 0rem 1rem 0.8rem 1rem;
    margin-top: 2rem;
  }
}
.homeHeaderLanguage__heading {
  font-size: 1.6rem;
  font-weight: bold;
  color: #333333;
  @media (min-width: 1600px) {
    font-size: 20px;
  }
}
.homeHeaderLanguage__searchBox {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
}
.homeHeaderLanguage__searchBox__image {
  position: absolute;
  left: 1rem;
}
.homeHeaderLanguage__searchBox__inputField {
  width: 100%;
  border: 0.1rem solid #cbcbcb;
  border-radius: 3rem;
  height: 3rem;
  padding-left: 3rem;
  font-size: 1.4rem;
  @media (min-width: 1600px) {
    font-size: 19px;
  }
}
.homeHeaderLanguage__searchBox__inputField:focus,
.homeHeaderLanguage__searchBox__inputField:focus-visible {
  caret-color: #cbcbcb;
  outline: none;
}
.homeHeaderLanguage__list {
  margin: 0;
  list-style: none;
  height: 17rem;
  overflow-y: scroll;
}
.homeHeaderLanguage__list::-webkit-scrollbar {
  width: 1rem;
}
.homeHeaderLanguage__list::-webkit-scrollbar-track {
  background: #fff;
}
.homeHeaderLanguage__list::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #ececec;
}
.homeHeaderLanguage__list__item {
  margin: 0.7rem 0rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  input{
    flex-shrink: 0;
  }
}
.homeHeaderLanguage__list__language {
  padding: 0rem 1rem;
  font-size: 1.5rem;
  color: #333333;
  text-align: left;
  @media (min-width: 1600px) {
    font-size: 19px;
  }
}
.homeHeaderLanguage__buttonArea {
  display: flex;
  justify-content: center;
  padding-top: 4%;
  align-items: center;
}
.homeHeaderLanguage__button {
  background: #fff;
  border: 1px solid #707070;
  border-radius: 3.5rem;
  color: #333333;
  min-width: 12rem;
  padding: 0.8rem 2rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4rem;
  @media (min-width: 1600px) {
    font-size: 18px;
    min-width: 11rem;
  }
}



html[dir="rtl"] {
  .homeHeaderLanguage{
    .homeHeaderLanguage__searchBox__inputField{
      padding-right: 3rem;
      padding-left: 0;
    }
    .homeHeaderLanguage__searchBox__image{
      left: initial;
      right: 1rem;
    }
  }
  @media only screen and (max-width: 768px) {
    .homeHeaderLanguage__list__language{
      text-align: right;
    }
  }
  
}